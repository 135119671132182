import { useId, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dataService from '../Common/Services/dataService';
import { CampaignType, Device } from '../types';
import { CampaignUploadDevicesList } from './CampaignUploadDevicesList/CampaignUploadDevicesList';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
} from '@mui/material';
import {
    StyledTableBodyRow,
    StyledTableHeaderRow
} from '@applications-terrains/birdz-react-library';
import useExtendableList from '../../hooks/useExtendableList';

type CampaignDeviceDevEUIProps = {
    onChange(devices: Device[], operator?: number): void;
    operatorId: number;
    campaignType: CampaignType;
};

const TableRow = ({ device }: { device: Device }) => {
    const { t } = useTranslation();
    const id = useId();

    return (
        <StyledTableBodyRow key={id}>
            <TableCell>{device?.deveui}</TableCell>
            <TableCell>{device?.module_address}</TableCell>
            <TableCell>{device?.concentrator}</TableCell>
            <TableCell>
                {device?.repeaters && device.repeaters?.length > 0 && (
                    <Table size="small">
                        <thead>
                            <tr>
                                <th>{t('repeater')}</th>
                                <th>{t('channel')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {device.repeaters.map((repeater: any, index: number) => {
                                return (
                                    <tr key={'device_repeaters_' + index}>
                                        <TableCell>{repeater?.module_address}</TableCell>
                                        <TableCell>{repeater?.channel}</TableCell>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                )}
            </TableCell>
            <TableCell>{dataService.getDeviceType(device?.device_type)}</TableCell>
            <TableCell>{dataService.getProtocol(device?.protocol)?.name}</TableCell>
        </StyledTableBodyRow>
    );
};

export const CampaignDeviceDevEUI = ({
    onChange,
    operatorId,
    campaignType
}: CampaignDeviceDevEUIProps) => {
    const { t } = useTranslation();
    const [devices, setDevices] = useState<Device[]>([]);
    const { listToDisplay, SeeMore } = useExtendableList(devices);

    return (
        <>
            <CampaignUploadDevicesList
                campaignType={campaignType}
                onChange={(devices, deviceString, operator) => {
                    onChange(devices, operator);
                    setDevices(devices);
                }}
                operatorId={operatorId}
            />

            {devices && devices.length > 0 && (
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <StyledTableHeaderRow>
                                <TableCell>DevEUI</TableCell>
                                <TableCell>{t('module_address')}</TableCell>
                                <TableCell>{t('concentrator')}</TableCell>
                                <TableCell>{t('repeaters')}</TableCell>
                                <TableCell>{t('module_type')}</TableCell>
                                <TableCell>{t('protocol')}</TableCell>
                            </StyledTableHeaderRow>
                        </TableHead>
                        <TableBody>
                            {(listToDisplay as Device[]).map((device, index) => (
                                <TableRow
                                    device={device}
                                    key={device.deveui + device.module_address + index}
                                />
                            ))}
                        </TableBody>
                    </Table>
                    <SeeMore />
                </TableContainer>
            )}
        </>
    );
};
