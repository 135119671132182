import axios from 'axios';
import { Operator, Protocol } from '../../types';

type Data = {
    [key: string]: any;
};

type CustomFieldsResults = {
    label?: string;
    value?: string;
};

class DataService {
    public dataToLoad: { name: string; endpoint: string }[] = [
        {
            name: 'encodersSpecs',
            endpoint: '/api/encoders-specs/?size=10000'
        },
        {
            name: 'messageTypes',
            endpoint: '/api/message-types/'
        },
        {
            name: 'deviceTypes',
            endpoint: '/api/device-types/?ordering=name&size=10000'
        },
        {
            name: 'protocols',
            endpoint: '/api/protocols/?ordering=name&size=10000'
        },
        {
            name: 'deviceGroups',
            endpoint: '/api/device-groups/?ordering=name&size=10000'
        },
        {
            name: 'operators',
            endpoint: '/api/operators/?ordering=name&size=10000'
        },
        {
            name: 'deviceManufacturers',
            endpoint: '/api/device-manufacturers/?ordering=name&size=10000'
        },
        {
            name: 'frameTemplates',
            endpoint: '/api/frame-templates/?size=10000&ordering=name'
        },
        {
            name: 'txrejectCauses',
            endpoint: '/api/txreject-causes/?size=10000'
        },
        {
            name: 'customers',
            endpoint: '/api/customers/?size=10000&ordering=name'
        },
        {
            name: 'contracts',
            endpoint: '/api/contracts/?size=10000&ordering=name'
        },
        {
            name: 'groups',
            endpoint: '/api/groups/?ordering=name&size=10000'
        },
        {
            name: 'sections',
            endpoint: '/api/sections/?ordering=code&size=10000'
        }
    ];

    public data: Data = {};

    // public async loadData() {
    //     const allPromises: any = [];

    //     this.dataToLoad.forEach((data) => {
    //         allPromises.push(axios.get(data.endpoint));
    //     });

    //     return Promise.all(allPromises).then(
    //         (allData) => {
    //             allData.forEach((response: any, dataIndex) => {
    //                 this.data[this.dataToLoad[dataIndex].name] =
    //                     response.data?.results || response.data;
    //             });
    //         },
    //         (error) => {
    //             //eslint-disable-next-line
    //             console.error(
    //                 "L'endpoint " + error?.response?.config?.url + " n'est pas joignable"
    //             );
    //         }
    //     );
    // }

    public async loadData() {
        const allPromises = this.dataToLoad.map((data) => axios.get(data.endpoint));

        const results = await Promise.allSettled(allPromises);

        results.forEach((result, dataIndex) => {
            if (result.status === 'fulfilled') {
                // Requête réussie
                this.data[this.dataToLoad[dataIndex].name] =
                    result.value.data?.results || result.value.data;
            } else {
                // Requête échouée
                const error = result.reason;
                console.error(
                    `L'endpoint ${error?.config?.url} a retourné ${error.response?.status}`
                );
                // Vous pourriez aussi stocker l'erreur ou un état d'erreur si besoin
            }
        });
    }
    
    public getData(dataName: string, filterCriteria: any = null) {
        let data = (this.data && this.data[dataName]) || [];
        if (filterCriteria) {
            const filterName = Object.keys(filterCriteria)[0];
            const filterValue = filterCriteria[filterName];
            data = data.filter((item: any) => item[filterName] === filterValue);
        }

        return data;
    }

    public getAllData() {
        return this.data;
    }

    public getDeviceType(deviceTypeId: number, getFullObject: boolean = false) {
        const deviceType = this.getData('deviceTypes').find(
            (deviceType: any) => deviceTypeId && deviceType.id === deviceTypeId
        );

        return getFullObject ? deviceType : deviceType?.name || '';
    }

    public getProtocol(protocolId: number): Protocol {
        const protocol = this.getData('protocols').find(
            (protocol: any) => protocolId && protocol.id === protocolId
        );
        return protocol;
    }

    public getOperator(operatorId: number): Operator {
        const operator = this.getData('operators').find(
            (operator: any) => operatorId && operator.id === operatorId
        );
        return operator;
    }

    public dataStoreInDataService(dataName: string) {
        return this.dataToLoad.find((data) => data.name === dataName);
    }

    public async refreshData(dataName: string) {
        return new Promise<void>((resolve, reject) => {
            const dataToLoadConfig = this.dataStoreInDataService(dataName);
            if (dataToLoadConfig) {
                axios.get(dataToLoadConfig.endpoint).then(
                    (response: any) => {
                        this.data[dataName] = response.data.results || response.data;
                        resolve();
                    },
                    () => {
                        reject();
                    }
                );
            } else {
                reject();
            }
        });
    }

    public getSelectData(
        dataName: string,
        filterCriteria: any = null,
        customFieldsResults?: CustomFieldsResults
    ) {
        return this.getData(dataName, filterCriteria).map((result: any) => {
            return {
                label: result[customFieldsResults?.label || 'label'],
                value: result[customFieldsResults?.value || 'id']
            };
        });
    }
}

const dataService = new DataService();
export default dataService;
